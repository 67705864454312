import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import { useMediaQuery } from 'react-responsive';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, plugins } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

function PeriodLineChart({ period, data }) {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  // 컴포넌트가 마운트될 때 30일 범위의 날짜를 설정
  useEffect(() => {
    const today = new Date();
    const pastDate = new Date();
    pastDate.setDate(today.getDate() - 30);

    const formatDate = (date) => date.toISOString().split('T')[0];

    setStartDate(formatDate(pastDate));
    setEndDate(formatDate(today));
  }, []);

  // 데이터에서 30일 범위의 데이터를 필터링하는 함수
  const filterDataByDateRange = (data, startDate, endDate) => {
    const filteredData = {};

    Object.keys(data).forEach((date) => {
      const parsedDate = date.replace(/\//g, '-'); // YYYY/MM/DD -> YYYY-MM-DD 형식 변환
      if (parsedDate >= startDate && parsedDate <= endDate) {
        filteredData[parsedDate] = data[date];
      }
    });

    return filteredData;
  };

  // 필터링된 데이터
  const filteredData = filterDataByDateRange(data, startDate, endDate);

  // 차트에서 사용할 날짜 라벨 생성 (필터링된 날짜 사용)
  const labels = Object.keys(filteredData);

  // 모든 값이 0인지 확인하는 함수
  const isAllZero = (arr) => arr.every(value => value === 0);

  // 데이터셋 변환
  const datasets = Object.keys(filteredData)
    .reduce((acc, date) => {
      Object.keys(filteredData[date]).forEach((product) => {
        if (!acc[product]) {
          acc[product] = { label: product, data: [], fill: false, lineTension: 0.1 };
        }
        acc[product].data.push(Number(filteredData[date][product])); // 수량을 숫자로 변환해서 추가
      });
      return acc;
    }, {});

  const chartData = {
    labels: labels,
    datasets: Object.values(datasets).filter(dataset => !isAllZero(dataset.data)), // 모든 값이 0인 경우 필터링
  };

  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const options = {
    responsive: true,
    maintainAspectRatio: true,
    aspectRatio: isMobile ? 1 : 2,
    plugins: {
      title: {
        display: true,
        text: period || 'Period Line Chart',
      },
    },
  }

  return (
    <div>
      <Line data={chartData} options={options} />
    </div>
  );
}

export default PeriodLineChart;
