// AuthContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [authState, setAuthState] = useState({
        isLoggedIn: false,
        username: '',
        role: '',
    });

    useEffect(() => {
        const session_id = localStorage.getItem('session_id');
        const expiry = localStorage.getItem('expiry');
        const currentTime = new Date().getTime();

        if (session_id && (!expiry || currentTime <= expiry)) {
            setAuthState({
                isLoggedIn: true,
                username: localStorage.getItem('user_name'),
                role: localStorage.getItem('user_role'),
            });
        }
    }, []);

    return (
        <AuthContext.Provider value={{ authState, setAuthState }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);

export const menuConfig = {
    admin: [
        { label: 'Dashboard', path: '/dashboard' },
        { label: 'Calendar', path: '/calendar' },
        { label: 'Stock', path: '/stock' },
        { label: 'Prediction', path: '/prediction' },
        { label: 'Training Data', path: '/data' },
        { label: 'Setting', path: '/settings' },
    ],
    user: [
        { label: 'Calendar', path: '/calendar' },
    ],
    guest: [
        { label: 'Calendar', path: '/calendar' },
    ],
};