import React from 'react';
import './HomePage.css'; // 추가: CSS 파일을 사용하여 스타일을 적용합니다.

function HomePage() {
  const greetings = [
    "Isn't today a nice day? How are you?",
    "Great to see you here!",
    "Hope you're doing well!",
    "Have a fantastic day!",
    "Wishing you a wonderful day!"
  ];

  // 랜덤 인덱스를 통해 인삿말을 선택합니다.
  const randomGreeting = greetings[Math.floor(Math.random() * greetings.length)];

  return (
    <div className="centered-container">
      <h1>Hi!</h1>
      <p>{randomGreeting}</p>
    </div>
  );
}

export default HomePage;
