import React, { useState, useEffect } from 'react';
import { Modal, Button } from "react-bootstrap";
import { Spinner } from 'react-bootstrap';
import { useAuth } from '../context/AuthContext';

import { putCalendarEventData, patchCalendarEventData, deleteCalendarEventData } from "../api/CalendarEventAPI";

const CalendarEventModal = ({ selectedEvent, status, setStatus, handleClose, refreshAllCalendarEvents }) => {
    const { authState } = useAuth();
    const [processWaiting, setProcessWaiting] = useState(false);
    const { role } = authState;
    const [currentEvent, setCurrentEvent] = useState({ ...selectedEvent });

    useEffect(() => {
        const newEvent = { ...currentEvent };
        setCurrentEvent(newEvent);
    }, []);

    const formatDate = (dateString) => {
        const date = new Date(dateString);

        if (isNaN(date)) {
            return 'Invalid date';
        }

        const options = {
            year: 'numeric',       // 연도 (4자리)
            month: 'short',        // 월 (Jan, Feb 형식)
            day: '2-digit',        // 날짜 (2자리)
            hour: '2-digit',       // 시간 (2자리, 12시간제 또는 24시간제)
            minute: '2-digit',     // 분 (2자리)
        };

        return date.toLocaleDateString(undefined, options);
    };

    const periodFormatDate = (dateString) => {
        const date = new Date(dateString);

        // 날짜 유효성 검사
        if (isNaN(date)) {
            return '';
        }

        // YYYY-MM-DD HH:mm 형식으로 변환
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 +1
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');

        return `${year}/${month}/${day} ${hours}:${minutes}`;
    };

    const handleAddClick = () => {
        if (processWaiting) {
            return;
        }
        setProcessWaiting(true);
        const newEvent = { ...currentEvent };
        const startTime = new Date(newEvent.start_time);
        const endTime = new Date(newEvent.end_time);
        if (isNaN(startTime.getTime()) || isNaN(endTime.getTime())) {
            setProcessWaiting(false);
            return;
        } else if (startTime > endTime) {
            return;
        }
        startTime.setHours(startTime.getHours() + 9);
        endTime.setHours(endTime.getHours() + 9);
        delete newEvent.start;
        delete newEvent.end;
        newEvent['start_time'] = startTime.toISOString().slice(0, 16);
        newEvent['end_time'] = endTime.toISOString().slice(0, 16);
        putCalendarEventData(newEvent).then(() => {
            handleClose();
            refreshAllCalendarEvents();
        });
    };

    const handleEditClick = () => {
        setStatus('edit');
    };

    const handleEditConfirmClick = () => {
        if (processWaiting) {
            return;
        }
        setProcessWaiting(true);
        patchCalendarEventData(currentEvent.event_id, currentEvent).then(() => {
            handleClose();
            refreshAllCalendarEvents();
        });
    };

    const handleDeleteClick = () => {
        if (processWaiting) {
            return;
        }
        setProcessWaiting(true);
        deleteCalendarEventData(currentEvent.event_id).then(() => {
            handleClose();
            refreshAllCalendarEvents();
        });
    };

    const handleInputChange = (key, value) => {
        setCurrentEvent((currentEvent) => ({
            ...currentEvent,
            [key]: value,
        }));
    };

    return (
        <Modal show={true} onHide={handleClose}>
            <Modal.Header>
                <Modal.Title className="d-flex align-items-center">
                    {status === 'add' || status === 'edit' ? (
                        <select
                            value={currentEvent.category || ''}
                            onChange={(e) => handleInputChange('category', e.target.value)}
                            className="form-select me-2 w-auto"
                        >
                            <option value="이벤트">이벤트</option>
                            <option value="오프라인">오프라인</option>
                            <option value="기획전">기획전</option>
                        </select>
                    ) : (
                        <span
                            className={`badge me-2 ${currentEvent.category === '이벤트'
                                ? 'event-category-event'
                                : currentEvent.category === '오프라인'
                                    ? 'event-category-offline'
                                    : currentEvent.category === '기획전'
                                        ? 'event-category-exhibition'
                                        : ''
                                }`}
                        >
                            {currentEvent.category}
                        </span>
                    )}
                    {status === 'add' || status === 'edit' ? (
                        <input
                            type="text"
                            value={currentEvent.title || ''}
                            onChange={(e) => handleInputChange('title', e.target.value)}
                            className="form-control d-inline-block w-auto"
                            placeholder="Enter title"
                        />
                    ) : (
                        <h3 className="m-0">{currentEvent.title}</h3>
                    )}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    status !== 'add' && (
                        <div>
                            <p className="text-muted small">
                                Last Updated:
                                <strong className="ms-1">{currentEvent.last_updated_by.name} </strong>
                                <span className="ms-1"> ({formatDate(currentEvent.last_updated_at)})</span>
                            </p>
                        </div>
                    )
                }
                {
                    (status === 'add' || status === 'edit') ? (
                        <form>
                            <div className="mb-3">
                                <label htmlFor="start_time">Start Time:</label>
                                <input
                                    type="datetime-local"
                                    id="start_time"
                                    name="start_time"
                                    value={currentEvent.start_time}
                                    onChange={(e) => handleInputChange('start_time', e.target.value)}
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="end_time">End Time:</label>
                                <input
                                    type="datetime-local"
                                    id="end_time"
                                    name="end_time"
                                    value={currentEvent.end_time}
                                    onChange={(e) => handleInputChange('end_time', e.target.value)}
                                />
                            </div>
                        </form>
                    ) : (
                        <div>
                            <strong>Period:</strong> {
                                periodFormatDate(currentEvent.start_time)
                            } ~ {
                                periodFormatDate(currentEvent.end_time)
                            }
                        </div>
                    )
                }
                {
                    (status === 'add' || status === 'edit') ? (
                        <div className="mb-3">
                            <label htmlFor="memo" className="form-label">Memo:</label>
                            <textarea
                                id="memo"
                                name="memo"
                                className="form-control"
                                value={currentEvent.memo}
                                onChange={(e) => handleInputChange('memo', e.target.value)}
                                rows="4"
                            />
                        </div>
                    ) : (
                        <div>
                            <strong>Memo:</strong> {currentEvent.memo}
                        </div>
                    )
                }
            </Modal.Body>
            <Modal.Footer>
                {processWaiting ? (
                    <>
                        <Spinner animation="border" role="status" />
                    </>
                ) : status === 'add' ? (
                    <>
                        <Button variant="primary" onClick={handleAddClick}>Add</Button>
                    </>
                ) : status === 'edit' ? (
                    <>
                        <Button variant="primary" onClick={handleEditConfirmClick}>Confirm</Button>
                    </>
                ) : role === 'admin' ? (
                    <>
                        <Button variant="primary" onClick={handleEditClick}>Edit</Button>
                        <Button variant="danger" onClick={handleDeleteClick}>Delete</Button>
                    </>
                ) : null}
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal >
    );
};


export default CalendarEventModal;