import React from 'react';
import { Chart } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    LineElement,
    PointElement,
    BarController,
    LineController,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

// Chart.js에 필요한 요소를 등록합니다.
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    LineElement,
    PointElement,
    BarController,
    LineController,
    Title,
    Tooltip,
    Legend
);

const ParetoChart = ({ period, data }) => {
    // 데이터 정렬 (절대값 기준)
    const sortedData = data.sort((a, b) => Math.abs(b.value) - Math.abs(a.value));

    // 절대값 총합 및 평균 계산
    const total = sortedData.reduce((acc, item) => acc + Math.abs(item.value), 0);

    // 누적 백분율 계산
    let cumulativePercentage = 0;
    const labels = sortedData.map(item => item.label);
    const values = sortedData.map(item => item.value);
    const cumulativePercentages = values.map(value => {
        cumulativePercentage += (Math.abs(value) / total) * 100;
        return cumulativePercentage;
    });

    // 차트 데이터 설정
    const chartData = {
        labels: labels,
        datasets: [
            {
                label: 'Values',
                data: values,
                backgroundColor: 'rgba(75, 192, 192, 0.5)',
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 1,
                type: 'bar',
            },
            {
                label: 'Cumulative %',
                data: cumulativePercentages,
                borderColor: 'rgba(255, 99, 132, 1)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
                type: 'line',
                yAxisID: 'y-axis-2',
            },
        ],
    };

    // 차트 옵션 설정
    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: period || 'Pareto Chart (Absolute Value)',
            },
        },
        scales: {
            x: {
                beginAtZero: true,
            },
            y: {
                beginAtZero: true,
                position: 'left',
                title: {
                    display: true,
                    text: 'Values',
                },
            },
            'y-axis-2': {
                beginAtZero: true,
                position: 'right',
                grid: {
                    drawOnChartArea: false, // 오른쪽 축이 겹치지 않도록 설정
                },
                title: {
                    display: true,
                    text: 'Cumulative Percentage (%)',
                },
            },
        },
    };

    return <Chart type="bar" data={chartData} options={options} />;
};

export default ParetoChart;
