export const getAllCalendarEvents = async () => {
    try {
        const response = await fetch('https://api.pemont-insight.com/calendar/event', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });
        if (response.status === 401) {
            throw new Error('Unauthorized');
        }
        const result = await response.json();
        return result;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
}

export const getCalendarEventData = async (event_id) => {
    try {
        const response = await fetch(`https://api.pemont-insight.com/calendar/event/${event_id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });
        if (response.status === 401) {
            throw new Error('Unauthorized');
        }
        const result = await response.json();
        return result;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
};

export const putCalendarEventData = async (data) => {
    try {
        const session_id = localStorage.getItem('session_id');
        const response = await fetch('https://api.pemont-insight.com/calendar/event', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + session_id
            },
            body: JSON.stringify(data)
        });
        if (response.status === 401) {
            throw new Error('Unauthorized');
        }
        return;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
};

export const patchCalendarEventData = async (event_id, data) => {
    try {
        const session_id = localStorage.getItem('session_id');
        const response = await fetch(`https://api.pemont-insight.com/calendar/event/${event_id}`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + session_id
            },
            body: JSON.stringify(data)
        });
        if (response.status === 401) {
            throw new Error('Unauthorized');
        }
        return;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
};

export const deleteCalendarEventData = async (event_id) => {
    try {
        const session_id = localStorage.getItem('session_id');
        const response = await fetch(`https://api.pemont-insight.com/calendar/event/${event_id}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + session_id
            }
        });
        if (response.status === 401) {
            throw new Error('Unauthorized');
        }
        return;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
}