import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { Spinner, Card, Row, Col, Container } from 'react-bootstrap';
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "moment/locale/ko";
import { Button } from "react-bootstrap";
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import { useAuth } from '../context/AuthContext';
import CalendarEventModal from '../components/CalendarEventModal';
import { redirectSessionOut } from '../api/Auth';
import { getAllCalendarEvents, patchCalendarEventData } from "../api/CalendarEventAPI";

import "./CalendarPage.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';

const DragAndDropCalendar = withDragAndDrop(Calendar);
const localizer = momentLocalizer(moment);
moment.locale("ko");

function CalendarPage() {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [processWaiting, setProcessWaiting] = useState(false);
  const [error, setError] = useState(null);
  const [status, setStatus] = useState(null);
  const { authState } = useAuth();
  const { role } = authState;
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        await refreshAllCalendarEvents();
      } catch (error) {
        if (error.message === 'Unauthorized') {
          redirectSessionOut(navigate);
          return;
        }
        console.error('Error fetching dashboard data:', error);
        setError('Failed to load data');
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [navigate]);

  const refreshAllCalendarEvents = async () => {
    const result = await getAllCalendarEvents();
    const newEvents = result["data"].map((event) => ({
      ...event,
      start: new Date(event.start_time),
      end: new Date(event.end_time),
    }));
    setEvents(newEvents);
  };

  // 드래그앤드롭 핸들러 (예시용)
  const onEventDrop = ({ event, start, end }) => {
    if (processWaiting) {
      return;
    }

    // 화면에 먼저 반영
    const updatedEvents = events.map((existingEvent) =>
      existingEvent === event ? { ...existingEvent, start, end } : existingEvent
    );
    setEvents(updatedEvents);

    // 서버에 반영
    setProcessWaiting(true);
    const newEvent = { ...event };
    newEvent.start_time = start.toISOString().slice(0, 16);
    newEvent.end_time = end.toISOString().slice(0, 16);
    patchCalendarEventData(newEvent.event_id, newEvent).then(() => {
      refreshAllCalendarEvents();
      setProcessWaiting(false);
    });
  };

  // 이벤트 바 색상 변경
  const customEventPropGetter = (event) => {
    switch (event.category) {
      case "이벤트":
        return {
          style: {
            backgroundColor: "rgba(13, 110, 253, 1)",
            color: "white", // 텍스트 색상
          },
        };
      case "오프라인":
        return {
          style: {
            backgroundColor: "rgba(138, 43, 226, 1)",
            color: "white",
          },
        };
      case "기획전":
        return {
          style: {
            backgroundColor: "rgba(255, 215, 0, 1)",
            color: "black",
          },
        };
      default:
        return {
          style: {
            backgroundColor: "rgba(200, 200, 200, 1)", // 기본 색상
            color: "black",
          },
        };
    }
  };

  // 이벤트 크기 조절 핸들러
  const onEventResize = ({ event, start, end }) => {
    if (processWaiting) {
      return;
    }

    // 화면에 먼저 반영
    const updatedEvents = events.map((existingEvent) =>
      existingEvent === event ? { ...existingEvent, start, end } : existingEvent
    );
    setEvents(updatedEvents);

    // 서버에 반영
    setProcessWaiting(true);
    const newEvent = { ...event };
    newEvent.start_time = start.toISOString().slice(0, 16);
    newEvent.end_time = end.toISOString().slice(0, 16);
    patchCalendarEventData(newEvent.event_id, newEvent).then(() => {
      refreshAllCalendarEvents();
      setProcessWaiting(false);
    });
  };

  // 요일 텍스트 색상 변경
  const customDayPropGetter = (date) => {
    const day = moment(date).day();
    if (day === 0) {
      return {
        style: {
          color: "red",
          backgroundColor: "rgba(255, 0, 0, 0.1)"
        }
      };
    }
    if (day === 6) {
      return {
        style: {
          color: "blue",
          backgroundColor: "rgba(58, 176, 255, 0.1)"
        }
      };
    }
    return {};
  };

  // 풀캘린더 버튼
  const handleAddClick = () => {
    setStatus("add");
    setSelectedEvent({
      title: "",
      start_time: "",
      end_time: "",
      memo: "",
      category: "이벤트",
      start: new Date(), // for fc
      end: new Date() // for fc
    });
  };


  // 모달 상태 관리
  const [selectedEvent, setSelectedEvent] = useState(null);

  const handleEventClick = (event) => {
    setSelectedEvent(event);
  };

  const handleClose = () => {
    setStatus(null);
    setSelectedEvent(null);
  };

  // 로딩 중이거나 에러가 발생한 경우의 화면 처리
  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
        <Spinner animation="border" role="status" />
      </div>
    );
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div>
      <Container fluid className="px-5">
        <h1 className="my-4">Calendar</h1>
        <Card className="mb-4">
          <Card.Body style={{ overflowX: 'auto', overflowY: 'auto' }}>
            <Card.Title>Events</Card.Title>
            {
              processWaiting ? (
                <>
                  <Spinner animation="border" role="status" />
                </>
              ) : null
            }
            {role === "admin" && (
              <Row className="mb-3 justify-content-end" style={{ paddingLeft: "10%", paddingRight: "10%" }}>
                <Col xs="auto">
                  <Button variant="primary" className="m-2" onClick={handleAddClick}>Add</Button>
                </Col>
              </Row>
            )}
            <div style={{ height: "100vh", width: "100%", paddingLeft: "10%", paddingRight: "10%" }}>
              {/* 캘린더 */}
              <DragAndDropCalendar
                localizer={localizer}
                events={events}
                startAccessor="start"
                endAccessor="end"
                style={{ height: "90vh" }}
                defaultView="month"
                dayPropGetter={customDayPropGetter}
                eventPropGetter={customEventPropGetter}
                onSelectEvent={handleEventClick}
                onEventDrop={onEventDrop}
                onEventResize={onEventResize}
              />

              {/* 모달 */}
              {selectedEvent && (
                <CalendarEventModal selectedEvent={selectedEvent} status={status} setStatus={setStatus} handleClose={handleClose} refreshAllCalendarEvents={refreshAllCalendarEvents} />
              )}
            </div>
          </Card.Body>
        </Card>
      </Container>
    </div>
  );
}

export default CalendarPage;
