import React from 'react';
import { Card } from 'react-bootstrap';


const aggregatePlatformData = (data) => {
    const aggregated = {};

    Object.values(data).forEach((monthData) => {
        Object.entries(monthData).forEach(([platform, value]) => {
            aggregated[platform] = (aggregated[platform] || 0) + value;
        });
    });

    return aggregated;
};

/**
const sumOfTopKValsFromAggregated = (data, k) => {
    if (typeof data !== "object" || data === null) {
        return 0;
    }

    return Object.entries(data)
        .sort(([, valueA], [, valueB]) => valueB - valueA)
        .slice(0, k)
        .reduce((sum, [, value]) => sum + value, 0);
};
 */

const labelOfTopKValsFromAggregated = (data, k) => {
    if (typeof data !== "object" || data === null) {
        return [];
    }

    return Object.entries(data)
        .sort(([, valueA], [, valueB]) => valueB - valueA)
        .slice(0, k)
        .map(([key]) => key);
};

const TopKRateRankingCard = ({ title, sample, population, k }) => {
    const rate_data = {};

    Object.keys(population).forEach((month) => {
        rate_data[month] = {};

        Object.keys(population[month]).forEach((platform) => {
            const populationValue = population[month][platform] || 0;
            const sampleValue = sample[month]?.[platform] || 0;

            rate_data[month][platform] = populationValue > 0 ? sampleValue / populationValue : 0;
        });
    });

    const aggregatedData = aggregatePlatformData(population);
    const aggregatedReturnsData = aggregatePlatformData(sample);
    //const topKSum = sumOfTopKValsFromAggregated(aggregatedData, k);
    const topKLabels = labelOfTopKValsFromAggregated(aggregatedData, k);

    const topKValues = topKLabels.map((label) => {
        // 각 플랫폼의 총합 비율 계산
        const totalSample = Object.values(sample).reduce((sum, monthData) => sum + (monthData[label] || 0), 0);
        const totalPopulation = Object.values(population).reduce((sum, monthData) => sum + (monthData[label] || 0), 0);
        return totalPopulation > 0 ? totalSample / totalPopulation : 0;
    });

    // Sort topKLabels and topKValues together by topKValues descending
    const sortedTopK = topKLabels
        .map((label, index) => ({ label, value: topKValues[index] }))
        .sort((a, b) => b.value - a.value);

    const sortedTopKLabels = sortedTopK.map((item) => item.label);
    const sortedTopKValues = sortedTopK.map((item) => item.value);

    const topKValuePercentage = sortedTopKValues.map((value) => value * 100);
    return (
        <Card className="shadow">
            <Card.Header className="text-center fw-bold">
                {title}
            </Card.Header>
            <Card.Body>
                <Card.Text>
                    <ul className="list-group list-group-flush">
                        {sortedTopKLabels.map((label, index) => (
                            <li className="list-group-item" key={label}>
                                {index + 1}. {label} {topKValuePercentage[index].toFixed(1)}% ({aggregatedReturnsData[label] ? aggregatedReturnsData[label] : 0})
                            </li>
                        ))}
                    </ul>
                </Card.Text>
            </Card.Body>
        </Card>
    );
};

export default TopKRateRankingCard;