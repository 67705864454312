export const retrieveExtensionVersion = async () => {
    const session_id = localStorage.getItem('session_id');
    try {
        const response = await fetch(`https://api.pemont-insight.com/extension/version`, {
            method: 'POST',
            headers: {
                "Authorization": "Bearer " + session_id
            }
        });
        if (response.status === 401) {
            throw new Error('Unauthorized');
        }
        const result = await response.json();
        return result;
    } catch (error) {
        throw error;
    }
};