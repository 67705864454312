import PlatformsEnum from '../constants/PlatformsEnum';

const getCookieValue = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) {
        return parts.pop().split(';').shift();
    }
    return null;
}

const retrieveSessions = async (platform) => {
    const sessions = JSON.parse(getCookieValue('sessions'));

    if (platform === PlatformsEnum.MUSINSA) {
        return sessions['MUSINSA'];

    } else if (platform === PlatformsEnum.CAFE24) {
        return sessions['CAFE24'];

    } else if (platform === PlatformsEnum.W_CONCEPT) {
        return sessions['W_CONCEPT'];

    } else if (platform === PlatformsEnum.OCO) {
        return sessions['OCO'];

    } else if (platform === PlatformsEnum.CM29) {
        return sessions['CM29'];

    } else if (platform === PlatformsEnum.EQL) {
        return sessions['EQL'];

    } else if (platform === PlatformsEnum.KREAM) {
        return sessions['KREAM'];
    }

    return null;
};

const retrieveAllSessions = async () => {
    return JSON.parse(getCookieValue('sessions'));
};

export { retrieveSessions, retrieveAllSessions };