import React, { useEffect, useRef } from 'react';
import { Chart } from 'chart.js/auto';
import { useMediaQuery } from 'react-responsive';

const StackedLineChart = ({ data }) => {
  const chartRef = useRef(null);
  const chartInstanceRef = useRef(null);

  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  useEffect(() => {
    const ctx = chartRef.current.getContext('2d');

    // Destroy previous chart instance if it exists
    if (chartInstanceRef.current) {
      chartInstanceRef.current.destroy();
    }

    // Create the new chart with the same style and options as the original `create_graph` function
    chartInstanceRef.current = new Chart(ctx, {
      type: 'bar', // Sticking with bar type as per the original function
      data: {
        labels: data.labels, // Use labels from the provided data
        datasets: data.datasets, // Use datasets from the provided data
      },
      options: {
        responsive: true,  // Ensures the chart is responsive
        maintainAspectRatio: true,
        aspectRatio: isMobile ? 1 : 2,
        scales: {
          y: {
            stacked: true,  // Stacks the bars vertically
            beginAtZero: true,  // Ensures the scale starts at zero
          },
          x: {
            stacked: true,  // Stacks the bars horizontally
          },
        },
        plugins: {
          legend: {
            position: 'top',  // Move the legend to the top
          },
          tooltip: {
            mode: 'index',  // Tooltips display per index
            intersect: false,  // Allows non-intersecting tooltips
          },
        },
      },
    });

    // Cleanup function to destroy the chart instance
    return () => {
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy();
      }
    };
  }, [data]);

  return <canvas ref={chartRef}></canvas>;
};

export default StackedLineChart;
