import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Table, Card, Button, Row, Col, Spinner } from 'react-bootstrap';
import { retrievePredictionData } from '../api/RetrievePredictionData';
import { updatePredictionData } from '../api/UpdatePredictionData';
import { redirectSessionOut } from '../api/Auth';
import { trainPredictionData, trainPredictionDataWithProduct } from '../api/TrainPredictionData';

function DataPage() {
  const [logs, setLogs] = useState("");
  const [dataRows, setDataRows] = useState([]);
  const [editRecordIndex, setEditRecordIndex] = useState(null);
  const [editField, setEditField] = useState(null);
  const [loading, setLoading] = useState(true);
  const [updating, setUpdating] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await retrievePredictionData();
        setDataRows(data);
        setLoading(false);
      } catch (error) {
        if (error.message === 'Unauthorized') {
          redirectSessionOut(navigate);
          return;
        }
      }
    };

    fetchData(); // 비동기 함수 호출
  }, [navigate]);

  const handleFieldClick = (recordIndex, field) => {
    setEditRecordIndex(recordIndex);
    setEditField(field);
  };

  const handleFieldChange = (e, recordIndex, field) => {
    const updatedRows = [...dataRows];
    updatedRows[recordIndex][field] = e.target.value;
    setDataRows(updatedRows);
  };

  const handleFieldBlur = () => {
    setEditRecordIndex(null);
    setEditField(null);
  };

  const addRow = () => {
    const newRow = {
      no: dataRows.length + 1,
      date: '2024-09',
      product_name: `New Product ${dataRows.length + 1}`,
      product_sales: 0,
      product_total_sales: 0,
      product_rate_count: 0,
      product_rate_average: 0.0,
      temperature: 0,
    };
    setDataRows([...dataRows, newRow]);
  };

  const deleteRow = (index) => {
    const updatedDataRows = dataRows.filter((_, i) => i !== index);
    const renumberedDataRows = updatedDataRows.map((data, i) => ({
      ...data,
      no: i + 1,
    }));
    setDataRows(renumberedDataRows);
  };

  const updatePredictionDataHandle = async () => {
    try {
      await updatePredictionData(dataRows);
    } catch (error) {
      if (error.message === 'Unauthorized') {
        redirectSessionOut(navigate);
        return;
      }
      console.error('Error updating prediction data:', error);
    }
  };

  const downloadTableAsCsv = () => {
    let csv = "No.,매출월,상품명,매출,총매출,총평가수,평균평가점수,기온(°C),id\n";
    dataRows.forEach((row, index) => {
      const rowData = [
        index + 1,
        row.date,
        row.product_name,
        row.product_sales,
        row.product_total_sales,
        row.product_rate_count,
        row.product_rate_average,
        row.temperature,
        row.id || ''
      ];
      csv += rowData.join(",") + "\n";
    });

    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", "data.csv");
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const importCsvToTable = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (event) => {
      const csv = event.target.result;
      const lines = csv.split('\n').slice(1); // Skip header line

      const newRows = lines.map(line => {
        const cells = line.split(",");
        return {
          no: cells[0],
          date: cells[1],
          product_name: cells[2],
          product_sales: cells[3] ? Number(cells[3]) : 0,
          product_total_sales: cells[4] ? Number(cells[4]) : 0,
          product_rate_count: cells[5] ? Number(cells[5]) : 0,
          product_rate_average: cells[6] ? Number(cells[6]) : 0,
          temperature: cells[7] ? Number(cells[7]) : 0,
          id: cells[8] || ''
        };
      }).filter(row => row.no !== "");

      setDataRows(newRows);
    };

    reader.readAsText(file);
  };

  // 학습 시작 로직
  const startTraining = async () => {
    try {
      setUpdating(true);
      const initialResponse = await trainPredictionData({});
      const product_names = initialResponse.product_names;

      const requests = product_names.map(async (product_name) => {
        const result = await trainPredictionDataWithProduct(product_name);
        setLogs((prevLogs) => `${prevLogs}\n${product_name}: ${result.summary}`);
      });

      await Promise.all(requests);
      setUpdating(false);
    } catch (error) {
      if (error.message === 'Unauthorized') {
        redirectSessionOut(navigate);
        return;
      }
      console.error("Error during training:", error);
    }
  };

  // 로딩 중이거나 에러가 발생한 경우의 화면 처리
  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
        <Spinner animation="border" role="status" />
      </div>
    );
  }

  let commonIndex = 1;

  return (
    <div className="data-page">
      <Container>
        <h1 className="my-4">Training Data</h1>

        <Card className="mb-4">
          <Card.Body style={{ overflowX: 'auto', overflowY: 'auto' }}>
            <Card.Title>Data Table</Card.Title>
            <Row className="mb-3 justify-content-end">
              <Col xs="auto">
                <Button variant="primary" className="m-2" onClick={addRow}>Add Row</Button>
                <Button variant="info" className="m-2" onClick={downloadTableAsCsv}>CSV Download</Button>
                <input type="file" accept=".csv" onChange={importCsvToTable} style={{ display: 'none' }} id="csvInput" />
                <Button variant="info" className="m-2" onClick={() => document.getElementById('csvInput').click()}>CSV Import</Button>
                <Button variant="info" className="m-2" onClick={updatePredictionDataHandle}>DB Upload</Button>
                <Button variant="success" className="m-2" onClick={startTraining}>Train</Button>
                {updating && <Spinner animation="border" role="status" />}
              </Col>
            </Row>
            <Table striped bordered hover>
              <thead className="text-center">
                <tr>
                  <th>No.</th>
                  <th>매출월</th>
                  <th>상품명</th>
                  <th>매출</th>
                  <th>총매출</th>
                  <th>총평가수</th>
                  <th>평균평가점수</th>
                  <th>기온(°C)</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody className="text-center">
                {dataRows.map((data, recordIndex) => (
                  <tr key={recordIndex}>
                    <td>{commonIndex++}</td>
                    <td onClick={() => handleFieldClick(recordIndex, 'date')}>
                      {editRecordIndex === recordIndex && editField === 'date' ? (
                        <input
                          type="text"
                          value={data.date}
                          onChange={(e) => handleFieldChange(e, recordIndex, 'date')}
                          onBlur={handleFieldBlur}
                          autoFocus
                        />
                      ) : (
                        data.date
                      )}
                    </td>
                    <td onClick={() => handleFieldClick(recordIndex, 'product_name')}>
                      {editRecordIndex === recordIndex && editField === 'product_name' ? (
                        <input
                          type="text"
                          value={data.product_name}
                          onChange={(e) => handleFieldChange(e, recordIndex, 'product_name')}
                          onBlur={handleFieldBlur}
                          autoFocus
                        />
                      ) : (
                        data.product_name
                      )}
                    </td>
                    <td onClick={() => handleFieldClick(recordIndex, 'product_sales')}>
                      {editRecordIndex === recordIndex && editField === 'product_sales' ? (
                        <input
                          type="number"
                          value={data.product_sales}
                          onChange={(e) => handleFieldChange(e, recordIndex, 'product_sales')}
                          onBlur={handleFieldBlur}
                          autoFocus
                        />
                      ) : (
                        data.product_sales
                      )}
                    </td>
                    <td onClick={() => handleFieldClick(recordIndex, 'product_total_sales')}>
                      {editRecordIndex === recordIndex && editField === 'product_total_sales' ? (
                        <input
                          type="number"
                          value={data.product_total_sales}
                          onChange={(e) => handleFieldChange(e, recordIndex, 'product_total_sales')}
                          onBlur={handleFieldBlur}
                          autoFocus
                        />
                      ) : (
                        data.product_total_sales
                      )}
                    </td>
                    <td onClick={() => handleFieldClick(recordIndex, 'product_rate_count')}>
                      {editRecordIndex === recordIndex && editField === 'product_rate_count' ? (
                        <input
                          type="number"
                          value={data.product_rate_count}
                          onChange={(e) => handleFieldChange(e, recordIndex, 'product_rate_count')}
                          onBlur={handleFieldBlur}
                          autoFocus
                        />
                      ) : (
                        data.product_rate_count
                      )}
                    </td>
                    <td onClick={() => handleFieldClick(recordIndex, 'product_rate_average')}>
                      {editRecordIndex === recordIndex && editField === 'product_rate_average' ? (
                        <input
                          type="number"
                          value={data.product_rate_average}
                          onChange={(e) => handleFieldChange(e, recordIndex, 'product_rate_average')}
                          onBlur={handleFieldBlur}
                          step="0.01"
                          autoFocus
                        />
                      ) : (
                        data.product_rate_average
                      )}
                    </td>
                    <td onClick={() => handleFieldClick(recordIndex, 'temperature')}>
                      {editRecordIndex === recordIndex && editField === 'temperature' ? (
                        <input
                          type="number"
                          value={data.temperature}
                          onChange={(e) => handleFieldChange(e, recordIndex, 'temperature')}
                          onBlur={handleFieldBlur}
                          autoFocus
                        />
                      ) : (
                        data.temperature
                      )}
                    </td>
                    <td>
                      <Button variant="danger" onClick={() => deleteRow(recordIndex)}>Delete</Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
        <Card className="mb-4">
          <Card.Body>
            <Card.Title>API Logs</Card.Title>
            <Row className="mb-3 justify-content-end">
              <Col xs="auto">
                <Button variant="danger" onClick={() => setLogs('')}>Clear</Button>
              </Col>
            </Row>
            <textarea
              className="form-control"
              rows="10"
              value={logs}
              readOnly
            />
          </Card.Body>
        </Card>
      </Container>
    </div>
  );
}

export default DataPage;
