function download(json, fileName) {
    json.then((result) => {

        if (result.hasOwnProperty("errorMessage")) {
            alert(result.errorMessage);
            return;
        }

        const a = document.createElement("a");
        a.href = result.url.replace(/['"]+/g, '');
        a.download = fileName;

        a.click();
    });
}

const downloadExtension = async () => {
    try {
        const session_id = localStorage.getItem('session_id');
        const response = await fetch('https://api.pemont-insight.com/extension', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + session_id
            }
        });
        if (response.status === 401) {
            throw new Error('Unauthorized');
        }
        download(response.json(), "extension.zip");
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
};

export { downloadExtension };