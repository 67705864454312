import React from 'react';
import { Chart } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    BarController,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

// Chart.js에 필요한 요소를 등록합니다.
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    BarController,
    Title,
    Tooltip,
    Legend
);

const colorPalette = [
    { background: 'rgba(255, 99, 132, 0.5)', border: 'rgba(255, 99, 132, 1)' },
    { background: 'rgba(54, 162, 235, 0.5)', border: 'rgba(54, 162, 235, 1)' },
    { background: 'rgba(255, 206, 86, 0.5)', border: 'rgba(255, 206, 86, 1)' },
    { background: 'rgba(75, 192, 192, 0.5)', border: 'rgba(75, 192, 192, 1)' },
    { background: 'rgba(153, 102, 255, 0.5)', border: 'rgba(153, 102, 255, 1)' },
    { background: 'rgba(255, 159, 64, 0.5)', border: 'rgba(255, 159, 64, 1)' },
    { background: 'rgba(99, 255, 132, 0.5)', border: 'rgba(99, 255, 132, 1)' },
    { background: 'rgba(162, 54, 235, 0.5)', border: 'rgba(162, 54, 235, 1)' },
    { background: 'rgba(206, 255, 86, 0.5)', border: 'rgba(206, 255, 86, 1)' },
    { background: 'rgba(192, 75, 192, 0.5)', border: 'rgba(192, 75, 192, 1)' },
];

const StackedBarChart = ({ period, data }) => {
    // 데이터 정렬: 총합 기준으로 내림차순
    const sortedData = data.sort((a, b) => {
        const totalA = Object.values(a.value).reduce((sum, val) => sum + val, 0);
        const totalB = Object.values(b.value).reduce((sum, val) => sum + val, 0);
        return totalB - totalA;
    });

    // 플랫폼(스택)의 모든 키 추출 (예: "무신사", "29CM", "Cafe24")
    const platforms = Array.from(
        new Set(sortedData.flatMap(item => Object.keys(item.value)))
    );

    // Chart.js 데이터셋 생성
    const datasets = platforms.map((platform, index) => {
        const color = colorPalette[index % colorPalette.length]; // 색상 순환
        return {
            label: platform,
            data: sortedData.map(item => item.value[platform] || 0), // 각 플랫폼의 값
            backgroundColor: color.background, // 미리 정의된 색상
            borderColor: color.border,
            borderWidth: 1,
        };
    });

    const labels = sortedData.map(item => item.label);

    const chartData = {
        labels: labels,
        datasets: datasets,
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: period || 'Stacked Bar Chart',
            },
        },
        scales: {
            x: {
                stacked: true, // x축 스택 활성화
            },
            y: {
                beginAtZero: true,
                stacked: true, // y축 스택 활성화
            },
        },
    };

    return <Chart type="bar" data={chartData} options={options} />;
};

export default StackedBarChart;
