
export const predictProportion = async (input_date) => {
    const session_id = localStorage.getItem('session_id');
    try {
        const response = await fetch('https://api.pemont-insight.com/prediction/predict', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + session_id
            },
            body: JSON.stringify({ "input_date": input_date })
        });
        if (response.status === 401) {
            throw new Error('Unauthorized');
        }
        const result = await response.json();
        return result;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
};