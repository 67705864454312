import React from 'react';
import { Card } from 'react-bootstrap';


function sumOfTopKVals(data, k) {
    if (!Array.isArray(data)) {
        return 0;
    }

    return data
        .map(item => item.value || 0)
        .sort((a, b) => b - a)
        .slice(0, k)
        .reduce((sum, val) => sum + val, 0);
}

function labelOfTopKVals(data, k) {
    if (!Array.isArray(data)) {
        return [];
    }

    return data
        .sort((a, b) => (b.value || 0) - (a.value || 0))
        .slice(0, k)
        .map(item => item.label);
}

function TopKCountRankingCard({ title, data, k }) {
    const topKSum = sumOfTopKVals(data, k);
    const topKLabels = labelOfTopKVals(data, k);
    const topKValues = topKLabels.map(label => data.find(item => item.label === label).value);
    const topKValuePercentage = topKLabels.map(label => data.find(item => item.label === label).value / topKSum * 100);

    return (
        <Card className="shadow">
            <Card.Header className="text-center fw-bold">
                {title}
            </Card.Header>
            <Card.Body>
                <Card.Text>
                    <ul className="list-group list-group-flush">
                        {topKLabels.map((label, index) => (
                            <li className="list-group-item">{index + 1}. {label} {topKValuePercentage[index].toFixed(1)}% ({topKValues[index]})</li>
                        ))}
                    </ul>
                </Card.Text>
            </Card.Body>
        </Card>
    );
}

export default TopKCountRankingCard;