import React, { useState, useEffect } from 'react';
import { Navbar, Nav, Container, Form, FormControl, Button, Spinner } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useAuth, menuConfig } from '../context/AuthContext';
import { login, logout, redirectSessionOut } from '../api/Auth';

function CustomNavbar() {
  const { authState, setAuthState } = useAuth();
  const { isLoggedIn, username, role } = authState;
  const [credentials, setCredentials] = useState({ id: '', pw: '' });
  const [errorMessage, setErrorMessage] = useState('');
  const [loginLoading, setLoginLoading] = useState(false);
  const navigate = useNavigate();

  // 유효 시간 만료 체크 및 리다이렉트 처리
  useEffect(() => {
    const expiry = localStorage.getItem("expiry");
    const currentTime = new Date().getTime();

    if (expiry && currentTime > expiry) {
      setAuthState({ isLoggedIn: false, username: '', role: '' });
      redirectSessionOut(navigate);
    } else {
      const session_id = localStorage.getItem('session_id');
      if (session_id) {
        setAuthState({ isLoggedIn: true, username: localStorage.getItem('user_name'), role: localStorage.getItem('user_role') });
      }
    }
  }, [navigate]);

  const handleLogin = async () => {
    setLoginLoading(true);
    const result = await login(credentials.id, credentials.pw);

    if (result.success) {
      setAuthState({ isLoggedIn: true, username: result.userName, role: result.userRole });
    } else {
      setErrorMessage(result.message);
    }

    setCredentials({ id: '', pw: '' });
    setLoginLoading(false);

    if (result.success) {
      window.location.href = "/";
    }
  };

  const handleLogout = () => {
    logout(navigate, "/");
    setAuthState({ isLoggedIn: false, username: '', role: '' });
  };

  const handleChange = (e) => {
    setCredentials({
      ...credentials,
      [e.target.name]: e.target.value
    });
  };

  const menus = menuConfig[role ? role : 'guest'] || [];

  return (
    <Navbar bg="dark" variant="dark" expand="lg">
      <Container fluid>
        <Navbar.Brand href="/">PEMONT INSIGHT</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            {menus.map((menu) => (
              <LinkContainer to={menu.path} key={menu.path}>
                <Nav.Link>{menu.label}</Nav.Link>
              </LinkContainer>
            ))}
          </Nav>
          {isLoggedIn ? (
            <Nav className="ms-auto">
              <Navbar.Text className="me-3">
                Signed in as: <a href="#login">{username}</a>
              </Navbar.Text>
              <Button variant="outline-danger" onClick={handleLogout}>
                Logout
              </Button>
            </Nav>
          ) : (
            <Form className="d-flex ms-auto" onKeyPress={(e) => { if (e.key === 'Enter') handleLogin(); }}>
              <FormControl
                type="text"
                placeholder="Username"
                className="me-2"
                aria-label="Username"
                name="id"
                value={credentials.id}
                onChange={handleChange}
              />
              <FormControl
                type="password"
                placeholder="Password"
                className="me-2"
                aria-label="Password"
                name="pw"
                value={credentials.pw}
                onChange={handleChange}
              />
              <Button variant="outline-success" onClick={handleLogin}>Login</Button>
            </Form>
          )}
          {loginLoading && <Spinner animation="border" variant="light" />}
          {errorMessage && <div style={{ color: 'red', marginTop: '10px' }}>{errorMessage}</div>}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default CustomNavbar;
