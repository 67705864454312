// fetch 함수
export const collectSalesFetch = async (sessions, selectedPlatforms, start_date, end_date, pemontSessionId) => {
    try {
        if (!sessions || !selectedPlatforms) {
            throw new Error("Invalid parameters");
        }

        const selectedPlatformsValues = Object.values(selectedPlatforms).map(
            (platform) => platform.value
        );

        const selectedPlatformsSessions = Object.fromEntries(
            Object.entries(sessions).filter(([key]) =>
                selectedPlatformsValues.includes(key)
            )
        );

        // 메시지 전송 (데이터를 처리한 뒤 React 컴포넌트로 전달)
        window.postMessage(
            {
                type: "fetch_data",
                api: "collectSales",
                selectedPlatformsSessions: selectedPlatformsSessions,
                startDate: start_date,
                endDate: end_date,
                pemontSessionId: pemontSessionId,
            },
            "*"
        );

        return true;
    } catch (error) {
        console.error("Error:", error);
        throw error;
    }
};