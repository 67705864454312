import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Card, Spinner, Tabs, Tab } from 'react-bootstrap';
import CircleChart from '../components/CircleChart';
import LineChart from '../components/LineChart';
import PeriodLineChart from '../components/PeriodLineChart';
import ParetoChart from '../components/ParetoChart';
import StackedBarChart from '../components/StackedBarChart';
import TopKCountRankingCard from '../components/TopKCountRankingCard';
import TopKRateRankingCard from '../components/TopKRateRankingCard';
import PeriodStackedBarChart from '../components/PeriodStackedBarChart';
import { retriveDashboardData } from '../api/RetrieveDashboardData';
import { redirectSessionOut } from '../api/Auth';

function DashboardPage() {
  // 데이터를 저장할 상태값
  const [salesProportionData, setSalesProportionData] = useState({});
  const [salesVolumeProportionData, setSalesVolumeProportionData] = useState({});
  const [montlySalesProportionData, setMontlySalesProportionData] = useState({});
  const [montlyReturnRateData, setMontlyReturnRateData] = useState({});
  const [montlyExchangeRateData, setMontlyExchangeRateData] = useState({});
  const [montlyStockChanges, setMontlyStockChanges] = useState({});
  const [orderChangeRateData, setOrderChangeRateData] = useState({});
  const [productReturnByProductData, setProductReturnByProductData] = useState({});
  const [returnCountByPlatformsData, setReturnCountByPlatformsData] = useState({});
  const [salesCountByProductData, setSalesCountByProductData] = useState({});
  const [salesReturnsCountByPlatformsData, setSalesReturnsCountByPlatformsData] = useState({});
  const [dailyOrderData, setDailyOrderData] = useState({});
  const [monthlyOrdersData, setMonthlyOrdersData] = useState({});
  const [monthlySucceedOrdersData, setMonthlySucceedOrdersData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [key, setKey] = useState('summary');
  const navigate = useNavigate();

  // 데이터를 가져오는 useEffect
  useEffect(() => {
    const fetchData = async () => {
      try {
        // 현재 날짜를 기준으로 계산
        const today = new Date();

        // 저번 달의 마지막 날 구하기
        const end_date = new Date(today.getFullYear(), today.getMonth(), 0);

        // 저번 달의 마지막 날로부터 364일 전 구하기
        const start_date = new Date(end_date);
        start_date.setDate(end_date.getDate() - 365);

        // 필요한 형식(예: YYYY-MM-DD)으로 변환
        const formatDate = (date) => {
          const year = date.getFullYear();
          const month = String(date.getMonth() + 1).padStart(2, '0');
          const day = String(date.getDate()).padStart(2, '0');
          return `${year}-${month}-${day}`;
        };

        const formattedEndDate = formatDate(end_date);
        const formattedStartDate = formatDate(start_date);

        const dashboardData = await retriveDashboardData({
          start_date: formattedStartDate,
          end_date: formattedEndDate
        });

        // 받아온 데이터를 각 차트에 맞게 설정
        setSalesProportionData(
          { data: dashboardData.monthly_sales.data, period: dashboardData.monthly_sales.period }
        );
        setSalesVolumeProportionData(
          { data: dashboardData.monthly_sales_volume.data, period: dashboardData.monthly_sales_volume.period }
        );
        setMontlySalesProportionData(
          { data: dashboardData.monthly_sales.data, period: dashboardData.monthly_sales.period },
        );
        setMontlyReturnRateData(
          { data: dashboardData.refund_count.data, period: dashboardData.refund_count.period },
        );
        setMontlyExchangeRateData(
          { data: dashboardData.exchange_count.data, period: dashboardData.exchange_count.period },
        );
        setMontlyStockChanges(
          { data: dashboardData.stock_changes.data, period: dashboardData.stock_changes.period },
        );
        setSalesCountByProductData(
          { data: dashboardData.sales_count_by_product.data, period: dashboardData.sales_count_by_product.period },
        );
        setSalesReturnsCountByPlatformsData(
          { data: dashboardData.sales_returns_count_by_platforms.data, period: dashboardData.sales_returns_count_by_platforms.period },
        );
        setReturnCountByPlatformsData(
          { data: dashboardData.return_count_by_platform.data, period: dashboardData.return_count_by_platform.period },
        );
        setProductReturnByProductData(
          { data: dashboardData.product_return_by_product.data, period: dashboardData.product_return_by_product.period },
        );
        setOrderChangeRateData(
          { data: dashboardData.order_change_rate_by_product.data, period: dashboardData.order_change_rate_by_product.period },
        );
        setDailyOrderData(
          { data: dashboardData.daily_order.data, period: dashboardData.daily_order.period },
        );
        setMonthlyOrdersData(
          { data: dashboardData.monthly_orders.data, period: dashboardData.monthly_orders.period },
        );
        setMonthlySucceedOrdersData(
          { data: dashboardData.monthly_successed_orders.data, period: dashboardData.monthly_successed_orders.period },
        );

        setLoading(false);

      } catch (err) {
        if (err.message === 'Unauthorized') {
          redirectSessionOut(navigate);
          return;
        }
        console.error('Error fetching dashboard data:', err);
        setError('Failed to load data');
        setLoading(false);
      }
    };

    fetchData();
  }, [navigate]);

  // 로딩 중이거나 에러가 발생한 경우의 화면 처리
  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
        <Spinner animation="border" role="status" />
      </div>
    );
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="Page">
      <Container>
        <Tabs
          id="dashboard-tab"
          defaultActiveKey="summary"
          activeKey={key}
          onSelect={(k) => setKey(k)}
          className="mb-3"
        >
          <Tab eventKey="summary" title="Summary">
            <Container>
              <Row className="g-4 mg-4">
                {/* Top 3 Sales */}
                <Col md={6}>
                  {
                    salesCountByProductData &&
                    <TopKCountRankingCard title="Top 5 Best-Selling Products" data={salesCountByProductData.data} k={5} />
                  }
                </Col>

                {/* Top Sales Return Rate by Platforms */}
                <Col md={6}>
                  {
                    salesReturnsCountByPlatformsData &&
                    <TopKRateRankingCard title="Top 5 Platforms with Highest Return Rate" sample={salesReturnsCountByPlatformsData.data} population={monthlySucceedOrdersData.data} k={5} />
                  }
                </Col>
              </Row>
              <Row className="g-4 mg-4">
                {/* Top Returns by Platforms */}
                <Col md={6}>
                  {
                    returnCountByPlatformsData &&
                    <TopKCountRankingCard title="Top 5 Platforms with Highest Returns" data={returnCountByPlatformsData.data} k={5} />
                  }
                </Col>
              </Row>
            </Container>
          </Tab>

          <Tab eventKey="sales" title="Sales">
            <Container>
              <Row className="g-4 mg-4">
                <Col md={6}>
                  <Card className="m-3">
                    <Card.Body>
                      <Card.Title>Total Monthly Sales</Card.Title>
                      {
                        salesProportionData &&
                        <CircleChart data={salesProportionData.data} period={salesProportionData.period} />
                      }
                    </Card.Body>
                  </Card>
                </Col>
                <Col md={6}>
                  <Card className="m-3">
                    <Card.Body>
                      <Card.Title>Total Monthly Sales Volume</Card.Title>
                      {
                        salesVolumeProportionData &&
                        <CircleChart data={salesVolumeProportionData.data} period={salesVolumeProportionData.period} />
                      }
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <Row className="g-4 mg-4">
                <Col md={12}>
                  <Card className="m-3">
                    <Card.Body>
                      <Card.Title>Monthly Sales Proportion</Card.Title>
                      <div style={{ height: '500px' }}>
                        {
                          montlySalesProportionData &&
                          <LineChart period={montlySalesProportionData.period} data={montlySalesProportionData.data} />
                        }
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Container>
          </Tab>

          <Tab eventKey="stock" title="Stock">
            <Container>
              <Row className="g-4 mg-4">
                <Col md={12}>
                  <Card className="m-3">
                    <Card.Body>
                      <Card.Title>Monthly Stock Changes</Card.Title>
                      {
                        montlyStockChanges &&
                        <PeriodLineChart period={montlyStockChanges.period} data={montlyStockChanges.data} />
                      }
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Container>
          </Tab>

          <Tab eventKey="orders" title="Orders">
            <Container>
              <Row className="g-4 mg-4">
                <Col md={12}>
                  <Card className="m-3">
                    <Card.Body>
                      <Card.Title>Monthly Order</Card.Title>
                      {
                        monthlySucceedOrdersData &&
                        <PeriodStackedBarChart period={monthlySucceedOrdersData.period} data={monthlySucceedOrdersData.data} />
                      }
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <Row className="g-4 mg-4">
                <Col md={12}>
                  <Card className="m-3">
                    <Card.Body>
                      <Card.Title>Daily Order</Card.Title>
                      {
                        dailyOrderData &&
                        <PeriodStackedBarChart period={dailyOrderData.period} data={dailyOrderData.data} />
                      }
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <Row className="g-4 mg-4">
                <Col md={12}>
                  <Card className="m-3">
                    <Card.Body>
                      <Card.Title>Order Change Rate by Product</Card.Title>
                      {
                        orderChangeRateData &&
                        <ParetoChart period={orderChangeRateData.period} data={orderChangeRateData.data} />
                      }
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Container>
          </Tab>

          <Tab eventKey="return/exchange" title="Return / Exchange">
            <Container>
              <Row className="g-4 mg-4">
                <Col md={12}>
                  <Card className="m-3">
                    <Card.Body>
                      <Card.Title>Return Rate by Platforms</Card.Title>
                      {
                        productReturnByProductData &&
                        <StackedBarChart period={productReturnByProductData.period} data={productReturnByProductData.data} />
                      }
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <Row className="g-4 mg-4">
                <Col md={12}>
                  <Card className="m-3">
                    <Card.Body>
                      <Card.Title>Monthly Exchange Rate</Card.Title>
                      {
                        montlyExchangeRateData &&
                        <LineChart period={montlyExchangeRateData.period} data={montlyExchangeRateData.data} />
                      }
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <Row className="g-4 mg-4">
                <Col md={12}>
                  <Card className="m-3">
                    <Card.Body>
                      <Card.Title>Montly Return Rate</Card.Title>
                      {
                        montlyReturnRateData &&
                        <LineChart period={montlyReturnRateData.period} data={montlyReturnRateData.data} />
                      }
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Container>
          </Tab>
        </Tabs>
      </Container>
    </div>
  );
}

export default DashboardPage;
