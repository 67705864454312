function download(json, fileName) {
  json.then((result) => {

    if (result.hasOwnProperty("errorMessage")) {
      alert(result.errorMessage);
      return;
    }

    const a = document.createElement("a");
    a.href = result.url.replace(/['"]+/g, '');
    a.download = fileName;

    a.click();
  });
}

const downloadSalesData = async (start_date, end_date, platforms) => {
  try {
    const session_id = localStorage.getItem('session_id');
    const response = await fetch('https://api.pemont-insight.com/download/sales/excel', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        "Authorization": "Bearer " + session_id
      },
      body: JSON.stringify({ "start_date": start_date, "end_date": end_date, "platforms": platforms })
    });
    if (response.status === 401) {
      throw new Error('Unauthorized');
    }
    download(response.json(), "sales_data.xlsx");
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};

export { downloadSalesData };