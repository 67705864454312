import React from 'react';
import { Chart } from 'react-chartjs-2';
import { useMediaQuery } from 'react-responsive';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    BarController,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

// Chart.js에 필요한 요소를 등록합니다.
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    BarController,
    Title,
    Tooltip,
    Legend
);

const colorPalette = [
    { background: 'rgba(255, 99, 132, 0.5)', border: 'rgba(255, 99, 132, 1)' },
    { background: 'rgba(54, 162, 235, 0.5)', border: 'rgba(54, 162, 235, 1)' },
    { background: 'rgba(255, 206, 86, 0.5)', border: 'rgba(255, 206, 86, 1)' },
    { background: 'rgba(75, 192, 192, 0.5)', border: 'rgba(75, 192, 192, 1)' },
    { background: 'rgba(153, 102, 255, 0.5)', border: 'rgba(153, 102, 255, 1)' },
    { background: 'rgba(255, 159, 64, 0.5)', border: 'rgba(255, 159, 64, 1)' },
    { background: 'rgba(99, 255, 132, 0.5)', border: 'rgba(99, 255, 132, 1)' },
    { background: 'rgba(162, 54, 235, 0.5)', border: 'rgba(162, 54, 235, 1)' },
    { background: 'rgba(206, 255, 86, 0.5)', border: 'rgba(206, 255, 86, 1)' },
    { background: 'rgba(192, 75, 192, 0.5)', border: 'rgba(192, 75, 192, 1)' },
];

const PeriodStackedBarChart = ({ data, period }) => {
    const sortedDates = Object.entries(data).sort(([dateA], [dateB]) =>
        new Date(dateA) - new Date(dateB)
    );

    const platforms = Array.from(
        new Set(sortedDates.flatMap(([, value]) => Object.keys(value)))
    );

    const datasets = platforms.map((platform, index) => {
        const color = colorPalette[index % colorPalette.length];
        return {
            label: platform,
            data: sortedDates.map(([, value]) => value[platform] || 0),
            backgroundColor: color.background,
            borderColor: color.border,
            borderWidth: 1,
        };
    });

    const labels = sortedDates.map(([date]) => date);

    const chartData = {
        labels: labels,
        datasets: datasets,
    };

    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

    const options = {
        responsive: true,
        maintainAspectRatio: true,
        aspectRatio: isMobile ? 1 : 2,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: `Period Stacked Bar Chart (${period})`,
            },
        },
        scales: {
            x: {
                stacked: true,
            },
            y: {
                beginAtZero: true,
                stacked: true,
            },
        },
    };

    return <Chart type="bar" data={chartData} options={options} />;
};

export default PeriodStackedBarChart;
